<!--  -->
<template>
  <div>
    <el-table :header-cell-style="{ background: '#F7F7F7' }" :data="tableData"
                    style="width: 100%; min-height: 350px" @sort-change="levelSort">

                    <el-table-column prop="userName" label="名称" align="center">
                    </el-table-column>
                    <el-table-column prop="levelName" align="center" label="等级" :show-overflow-tooltip="true" sortable>
                    </el-table-column>
                    <el-table-column prop="storeName" align="center" label="门店" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="studyMinutes" align="center" label="学习时长" :show-overflow-tooltip="true" sortable>
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center">
                                {{ scope.row.studyMinutes | stime }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address"  align="center" label="操作">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center">
                                <el-link type="primary" :underline="false" @click='details(scope.row)'>查看详情</el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
        tableData:[]
    }
  },
  filters: {
        stime(value) {
            return parseInt(value / 60) + '小时' + value % 60 + '分'
        }
    },
  created(){
    this.tableData=JSON.parse(this.$route.query.tableData)
  },
  methods:{
    details(row) {//查看详情
            this.$router.push({ path: '/beauticiandetails2', query: { row: JSON.stringify(row) } })
        },
     levelSort(column){
            let list=this.tableData
            if(column.prop=='levelName'){
                if(column.order=='ascending'){//升序
                    list = list.sort(this.sortLevelTop('levelId'))
                    this.tableData = list
                }else if(column.order=='descending'){//降序
                    list = list.sort(this.sortLevelBottom('levelId'))
                    this.tableData = list
                }else{
                   this.tableData=JSON.parse(this.$route.query.tableData)
                }
                this.tableData = list
            }else if(column.prop=='studyTimeMinutes'){
                if(column.order=='ascending'){//升序
                    list = list.sort(this.sortLevelTop('studyMinutes'))
                    this.tableData = list
                }else if(column.order=='descending'){//降序
                     list = list.sort(this.sortLevelBottom('studyMinutes'))
                     this.tableData = list
                }else{
                   this.tableData=JSON.parse(this.$route.query.tableData)
                }
                
            }
        },
        sortLevelTop: function (prop) {//升序
            return function (obj1, obj2) {
                var val1 = obj1[prop]
                var val2 = obj2[prop]
                if (val1 < val2) {
                    return -1
                } else if (val1 > val2) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        sortLevelBottom: function (prop) {//降序
            return function (obj1, obj2) {
                var val1 = obj2[prop]
                var val2 = obj1[prop]
                if (val1 < val2) {
                    return -1
                } else if (val1 > val2) {
                    return 1
                } else {
                    return 0
                }
            }
        },
  }
}
</script>

<style lang='less' scoped>

</style>
